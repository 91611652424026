import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { Flags, getClientSideFlags, syncClientSideFlags } from "@sciam/shared";
import { usePageData } from "./use-page";

const FlagsContext = createContext<Flags>({});

export const ProvideFlags = ({ children }) => {
  // @TODO: This doesn't exist in SSR yet.
  // For SSR support, we'll need to pass this property from renderPage() in the Express app, derived from the request object and/or cookies.
  const { initialFlags = null } = usePageData();
  const [flags, setFlags] = useState(initialFlags as Flags | null);

  useEffect(() => {
    syncClientSideFlags();
    // Combine initial flags with client-side flags
    setFlags({ ...initialFlags, ...getClientSideFlags() });
  }, []);

  const value = useMemo(() => flags, [flags]);

  return <FlagsContext.Provider value={value} children={children} />;
};

/**
 * Get the flags object from the page context
 */
export const useFlags = () => useContext(FlagsContext);
