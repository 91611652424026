import { useCallback, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { usePiano } from "@sciam/piano/react";
import { useEnvironment } from "~core/hooks/use-environment";
import { AUTH_SYNC_EXCEPTIONS } from "./constants";

export function useAuth() {
  // The auth environment can is derived from
  // 1. the build environment(e.g. local, staging, betas, or production)
  // 2. an overridden Piano environment, e.g. ?debug=piano-preprod
  // 3. explicit override via flag parameter, e.g. ?flag=auth0
  const { auth } = useEnvironment() || {};
  const isAuth0 = auth?.provider === "auth0";

  const piano = usePiano();
  const auth0 = useAuth0();

  const authState = isAuth0
    ? {
        isLoggedIn: auth0.isAuthenticated,
        isLoading: auth0.isLoading || piano.isLoading,
        isMaybeLoggedIn: auth0.isAuthenticated || piano.isMaybeLoggedIn,
        user: auth0.user && {
          // Backwards compatibility with Piano user object
          uid: auth0.user["https://sciam.com/user_id"],

          // Prefer the namespaced claims over given/family (which might not be set)
          firstName: auth0.user["https://sciam.com/first_name"] || auth0.user.given_name,
          lastName: auth0.user["https://sciam.com/last_name"] || auth0.user.family_name,

          ...auth0.user,
        },
      }
    : {
        isLoggedIn: piano.isLoggedIn,
        isMaybeLoggedIn: piano.isMaybeLoggedIn,
        isLoading: piano.isLoading,
        user: piano.user && {
          // This is *sometimes* not set by Piano so let's give it a default
          uid: piano.user?.uid || auth0.user?.sub,
          given_name: piano.user?.firstName,
          family_name: piano.user?.lastName,

          // Backwards compatibility with Auth0 user object
          ["https://sciam.com/user_id"]: piano.user?.uid,
          ["https://sciam.com/first_name"]: piano.user?.firstName,
          ["https://sciam.com/last_name"]: piano.user?.lastName,

          ...piano.user,
        },
      };

  /**
   * Login the user
   * @param {boolean|string} redirect Whether to redirect to the login page or open a popup
   */
  const login = useCallback(
    (redirect = true) => {
      if (!isAuth0) {
        return piano.showLoginModal();
      }

      // This will change current window to the login page and redirect back to the current page (up to around 5 redirects total)
      if (redirect) {
        const returnTo =
          typeof redirect === "string"
            ? redirect
            : window.location.pathname + window.location.search;
        return auth0.loginWithRedirect({
          appState: { returnTo },
          authorizationParams: { screen_hint: "login" },
        });
      }

      // Opens a popup window and closes when authenticated, maintaining page state without a reload
      return auth0.loginWithPopup({ authorizationParams: { screen_hint: "login" } });
    },
    [auth?.provider, auth0.loginWithRedirect, piano.showLoginModal],
  );

  /**
   * Register the user
   * @param {boolean|string} redirect Whether to redirect to the register page or open a popup
   */
  const register = useCallback(
    (redirect = true) => {
      if (!isAuth0) {
        return piano.showRegisterModal();
      }

      if (redirect) {
        const returnTo =
          typeof redirect === "string"
            ? redirect
            : window.location.pathname + window.location.search;
        return auth0.loginWithRedirect({
          appState: { returnTo },
          authorizationParams: {
            screen_hint: "signup",
          },
        });
      }

      return auth0.loginWithPopup({ authorizationParams: { screen_hint: "signup" } });
    },
    [auth?.provider, auth0.loginWithRedirect, piano.showRegisterModal],
  );

  /**
   * Logout
   *
   * For Auth0, this will redirect to the Auth0 logout page and then back to the current page
   */
  const logout = useCallback(
    (redirect = true) => {
      if (!isAuth0) {
        return piano.logout();
      }

      const returnToRaw =
        typeof redirect === "string" ? redirect : window.location.pathname + window.location.search;

      // Account pages require auth, so in this case, we want to redirect to the homepage
      const returnTo = returnToRaw.replace("/account/", "/");

      return auth0.logout({
        client_id: import.meta.env.PUBLIC_AUTH0_CLIENT_ID,
        logoutParams: {
          // Go to our dedicated logout page, which will clear the Piano JWT, clean up Piano JS SDK's
          // client-side state, then redirect back to the current page
          returnTo: window.location.origin + "/logout/?returnTo=" + returnTo,
        },
      });
    },
    [auth?.provider, auth0.logout, piano.logout],
  );

  return {
    // Pass auth env for convenience
    authEnv: auth,

    // Authentication State
    ...authState,

    // Authentication Actions
    login,
    register,
    logout,
  };
}

export function useAuthSyncError() {
  const { isLoggedIn, user, isLoading } = useAuth();
  const {
    isLoading: isPianoAuthLoading,
    isLoggedIn: isPianoLoggedIn,
    user: initialPianoUser,
  } = usePiano();

  // @TODO: Make a more robust check for Piano user desyncs
  // const [userInfoLoading, setUserInfoLoading] = useState(true);
  // const [pianoUser, setPianoUser] = useState(null);

  // usePianoInit(() => {
  //   setUserInfoLoading(false);
  //   console.log("[auth] [piano] user info", initialPianoUser);
  //   window.tp.user.getUserInfoParams((pianoUser) => {
  //     setPianoUser(pianoUser);
  //     setUserInfoLoading(false);
  //     console.log("[auth] [piano] user info", pianoUser);
  //   });
  // });

  /** useAuth() and usePiano() don't agree on whether the user is logged in */
  const loginMismatch = !!isLoggedIn != !!isPianoLoggedIn;
  /** Piano JWT is missing */
  const pianoJwtMissing = loginMismatch && !isPianoLoggedIn;
  /** Auth0 JWT is missing */
  const auth0JwtMissing = loginMismatch && !isLoggedIn;

  // The desync errors below rely on Piano's __utp cookie being set, which is *not* a functional requirement.

  /** Piano JWT is out of sync with the user info. This might be fine if the emails match */
  const pianoJwtDesync = initialPianoUser?.uid && initialPianoUser.uid !== user?.uid;
  /** Auth0 JWT is out of sync with the user info...and the emails don't match */
  const pianoEmailDesync = initialPianoUser?.uid && initialPianoUser?.email !== user?.email;

  /** @typedef {typeof AUTH_SYNC_EXCEPTIONS[keyof typeof AUTH_SYNC_EXCEPTIONS]} AuthException */
  const [errors, setErrors] = useState(/** @type {AuthException[]} */ ([]));
  useEffect(() => {
    const newErrors = [];
    if (pianoJwtMissing) newErrors.push(AUTH_SYNC_EXCEPTIONS.piano_jwt_missing);
    if (auth0JwtMissing) newErrors.push(AUTH_SYNC_EXCEPTIONS.auth0_jwt_missing);
    if (loginMismatch) newErrors.push(AUTH_SYNC_EXCEPTIONS.login_mismatch);
    if (pianoEmailDesync) newErrors.push(AUTH_SYNC_EXCEPTIONS.email_mismatch);
    if (pianoJwtDesync) newErrors.push(AUTH_SYNC_EXCEPTIONS.uid_mismatch);
    setErrors(newErrors);
  }, [pianoJwtMissing, auth0JwtMissing, loginMismatch, pianoJwtDesync, pianoEmailDesync]);

  const ready = !isLoading && !isPianoAuthLoading;
  const hasWarning = ready ? errors.some((error) => error.level === "warn") : null;
  const hasError = ready ? errors.some((error) => error.level === "error") : null;

  return {
    hasError,
    hasWarning,
    errors,
  };
}

export default useAuth;
